import React from 'react'
import { Link } from "gatsby"

const AboutItem = ({ icon, title, content, cols, link, children }) => {
    return (
        <div className={'about__item col-md-' + cols}>
            <div className="about__item__inner">
            {icon}

            { title &&
                <h3 className="about__item__title">
                    {title}
                </h3>}

            { content &&
                <p className="about__item__content">
                    {content}
                </p>
            }

            { link &&
                <Link to={link} />
            }

            {children}
            </div>
        
        </div>
    )

}

export default AboutItem