import React from "react"
import Layout from "../components/layout/layout"
import SEO from "../components/seo"
import TitleBlock from "../components/title-block/title-block"
import AboutItem from "../components/about/about-item"
import Icon1 from "../assets/satisfaction.svg"
import Icon2 from "../assets/solution.svg"
import Icon3 from "../assets/money-transfer.svg"
import Icon4 from "../assets/subscription.svg"
import Icon5 from "../assets/gift-box.svg"
import Icon6 from "../assets/promotion.svg"

const AboutPage = () => (
  <Layout>
    <SEO title="About Us" />
    <TitleBlock title="About Us" subtitle="A little bit about us" />
    <div className="container">
      <h4>Why choose us among all?</h4>
      <p>At RPM we're not just another accounting company. We're always aiming to accelerate your business further by making sure we provide you with the customised and personalised solution.</p>

      <div className="row">
        <AboutItem icon={<Icon1 />} cols="4" content="Excellent track record with 100% satisfied clients providing you with 24/7 support." />
        <AboutItem icon={<Icon2 />} cols="4" content="Provide you with timely advice and solution" />
        <AboutItem icon={<Icon3 />} cols="4" content="We automatically process your bank transactions" />
        <AboutItem icon={<Icon4 />} cols="4" content="Providing our clients free 1 year xero subscription “bringing savings” from the first day of signing up with us." />
        <AboutItem icon={<Icon5 />} cols="4" content="Absolutely no charges for setup, consultation and meetings once you sign up with us. You heard it right FREE!!!!" />
        <AboutItem icon={<Icon6 />} cols="4" content="Keeping you in loop with every step before we pay taxes for you." />
      </div>

      {/* <div className="row">
        <div className="col-md-6">
          <h4>Our Mission</h4>
          <p>Lorem ipsum....</p>
        </div>
        <div className="col-md-6">
          <p>lorem ipsum...</p>
        </div>
      </div> */}
    </div>
  </Layout>
)

export default AboutPage
